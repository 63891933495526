import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';

import Validation from './LoginValidation';

function LogOut(){
	
	const navigate = useNavigate();
	useEffect(() => {
		sessionStorage.removeItem('token');
		navigate("/");
	})
	return (
		<div>
		</div>
	)
}
export default LogOut ;
