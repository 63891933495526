// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0 30px;
  font-family: "Poppins";
  background: #f1f1f1;
}
h1,h2,h3,h4 {
  font-weight: 500;
}
.App {
  font-size: 1.2em;
  margin: 10px auto;
  width: 100%;
  max-width: 1200px;
  padding: 20px;
  box-sizing: border-box;
}
a {
  text-decoration: none;
  color: #8e2ad6;
  border-bottom: 1px dotted;
}
.site-header h1 {
  font-size: 1.5em;
  color: #8e2ad6;
  padding-bottom: 10px;
  border-bottom: 2px solid;
}
.create {
	max-width:400px;
	margin: 0 auto;
	text-align: cnter;
}
.create label {
	text-align: left;
	display: block;
}
.create h2{
	font-size: 20px;
	color: #f1356d;
	margin-bottom: 30px;
}
.create input, .create textarea, .create{
	width: 100%;
	padding: 6px 10px;
	margin: 10px 0;
	border: 1px solid #ddd;
	box-sizing: border-box;
	display: block;
}
.create button{
	background: #f1356d;
	color: #fff;
	border: 0;
	padding: 8px;
	border-radius:8px;
	cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;EACE,cAAc;EACd,sBAAsB;EACtB,mBAAmB;AACrB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,WAAW;EACX,iBAAiB;EACjB,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,qBAAqB;EACrB,cAAc;EACd,yBAAyB;AAC3B;AACA;EACE,gBAAgB;EAChB,cAAc;EACd,oBAAoB;EACpB,wBAAwB;AAC1B;AACA;CACC,eAAe;CACf,cAAc;CACd,iBAAiB;AAClB;AACA;CACC,gBAAgB;CAChB,cAAc;AACf;AACA;CACC,eAAe;CACf,cAAc;CACd,mBAAmB;AACpB;AACA;CACC,WAAW;CACX,iBAAiB;CACjB,cAAc;CACd,sBAAsB;CACtB,sBAAsB;CACtB,cAAc;AACf;AACA;CACC,mBAAmB;CACnB,WAAW;CACX,SAAS;CACT,YAAY;CACZ,iBAAiB;CACjB,eAAe;AAChB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');\n\nbody {\n  margin: 0 30px;\n  font-family: \"Poppins\";\n  background: #f1f1f1;\n}\nh1,h2,h3,h4 {\n  font-weight: 500;\n}\n.App {\n  font-size: 1.2em;\n  margin: 10px auto;\n  width: 100%;\n  max-width: 1200px;\n  padding: 20px;\n  box-sizing: border-box;\n}\na {\n  text-decoration: none;\n  color: #8e2ad6;\n  border-bottom: 1px dotted;\n}\n.site-header h1 {\n  font-size: 1.5em;\n  color: #8e2ad6;\n  padding-bottom: 10px;\n  border-bottom: 2px solid;\n}\n.create {\n\tmax-width:400px;\n\tmargin: 0 auto;\n\ttext-align: cnter;\n}\n.create label {\n\ttext-align: left;\n\tdisplay: block;\n}\n.create h2{\n\tfont-size: 20px;\n\tcolor: #f1356d;\n\tmargin-bottom: 30px;\n}\n.create input, .create textarea, .create{\n\twidth: 100%;\n\tpadding: 6px 10px;\n\tmargin: 10px 0;\n\tborder: 1px solid #ddd;\n\tbox-sizing: border-box;\n\tdisplay: block;\n}\n.create button{\n\tbackground: #f1356d;\n\tcolor: #fff;\n\tborder: 0;\n\tpadding: 8px;\n\tborder-radius:8px;\n\tcursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
