const fetchDataFromServer = async (url, options = {}) => {
	try {
		const response = await fetch(url, options);
		if (!response.ok) {
			throw new Error(`Error: ${response.status} ${response.statusText}`);
		}
		const data = await response.json();
		return data;
	} catch (error) {
		console.error('Failed to fetch data:', error);
		throw error;
	}
};

const getUsers = async(codeValue) => {
	let url ;
	if(codeValue == 1 || codeValue ==2){
   		url = 'https://tst.catchacoach.systems/api/users/?fields=email&filters[type]=Trainee'; // Replace with your API endpoint
	}
	if(codeValue == 3){
   		url = 'https://tst.catchacoach.systems/api/users/?fields=email&filters[type]=Coach'; // Replace with your API endpoint
	}
	const token = sessionStorage.getItem('token'); // Assuming you're using a token for authentication
	const options = {
       	method: 'GET',
        headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${token}`,
		},
	};
	const response = await fetchDataFromServer(url, options);
	return response ;
};


export default getUsers
