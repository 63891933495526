import React from 'react'
import { Link } from 'react-router-dom'

export default function SiteHeader(){
	return (
		<div className="sit-header">
			<Link to="/"><h1>Ninja Rviews</h1></Link>
		</div>
	)
}
