import React , { useEffect } from 'react'
import Layout from './Layout'; // Import your Navbar component
import Carousel from 'react-bootstrap/Carousel';
//text-center d-flex align-items-center min-vh-100

const Homepage = () => {
    return (
	<Layout  >
	<div class="container"> 
<div class="row mb-3">
<div id="carouselExampleDark" class="carousel carousel-dark slide ">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active" data-bs-interval="10000">
      <img src="https://catchacoach.de/wp-content/uploads/2020/10/nutritions.jpg" class="d-block w-100" alt="..." style={{ height: '400px' }}></img>
      <div class="carousel-caption d-none d-md-block">
        <h5>First slide label</h5>
        <p>Some representative placeholder content for the first slide.</p>
      </div>
    </div>
    <div class="carousel-item" data-bs-interval="2000">
      <img src="https://catchacoach.de/wp-content/uploads/2024/05/f10cfd0d-e330-4d85-ae8d-4e361f9fa04a.png" class="d-block w-100" alt="..." style={{ height: '400px' }}></img>
      <div class="carousel-caption d-none d-md-block">
        <h5>Second slide label</h5>
        <p>Some representative placeholder content for the second slide.</p>
      </div>
    </div>
    <div class="carousel-item">
      <img src="https://catchacoach.de/wp-content/uploads/2020/10/yoga.jpg" class="d-block w-100" alt="..."style={{ height: '400px' }}></img>
      <div class="carousel-caption d-none d-md-block">
        <h5>Third slide label</h5>
        <p>Some representative placeholder content for the third slide.</p>
      </div>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
</div>
	    <div class="row">
		<div class="col-md-6 d-flex align-items-center min-vh-100">
		    <div class="container">
	    	        <h1>Live a Healthy Life.</h1>
		        <h1>Learn From Experts.</h1>
	  	    </div>
		</div>
		<div class="col-md-6">
		    <img src="https://catchacoach.de/wp-content/uploads/2024/05/eada40c3-a2b2-490d-9234-490ddebbd996.png" class="img-fluid" alt="Image description"></img>
		</div>
	    </div>
	    <div class="row">  
 	        <div class="col-md-4"> 
	            <h2>Content 3</h2>
		    <p>This is some content for the first section of your page.</p>
		    <img src="https://catchacoach.de/wp-content/uploads/2020/10/yoga.jpg" class="img-thumbnail rounded" alt="..."></img>

	        </div>
	        <div class="col-md-4">
	            <h2>Content 4</h2>
		    <p>This is some content for the first section of your page.</p>
		    <img src="https://catchacoach.de/wp-content/uploads/2020/10/nutritions.jpg" class="img-thumbnail rounded " alt="..."></img>
	        </div>
	        <div class="col-md-4">
	            <h2>Content 5</h2>
		    <p>This is some content for the first section of your page.</p>
		    <img src="https://catchacoach.de/wp-content/uploads/2024/05/f10cfd0d-e330-4d85-ae8d-4e361f9fa04a.png" class="img-thumbnail rounded mx-auto d-block" alt="..."></img>
	        </div>
	    </div>
	    <div class="row">  
 	        <div class="col-md-4"> 
	            <h2>Content 6</h2>
		    <p>This is some content for the first section of your page.</p>
		    <img src="https://catchacoach.de/wp-content/uploads/2024/05/Untitled.png" class="img-thumbnail" alt="..."></img>
	        </div>
	        <div class="col-md-4">
	            <h2>Content 7</h2>
		    <p>This is some content for the first section of your page.</p>
		    <img src="https://catchacoach.de/wp-content/uploads/2024/05/man-runner-running-8293794-1.jpg" class="img-thumbnail" alt="..."></img>
	        </div>
	        <div class="col-md-4">
	            <h2>Content 8</h2>
		    <p>This is some content for the first section of your page.</p>
		    <img src="..." class="img-thumbnail" alt="..."></img>
	        </div>
	    </div>
	</div>		
	</Layout>
  	);
};


export default Homepage;
