import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';

import Validation from './LoginValidation';

function LogIn(){
	const navigate = useNavigate();
	const [values, setValues] = useState ({
		email: '',
		pasword: ''
	})
	const [ errors, setErrors] = useState ({})

	const handleInput = (event) => {
		setValues(prev => ({...prev, [event.target.name]:[event.target.value]}))
	}

	const handleSubmit =(event) => {
		if( ( (values.email)[0] == "mohamed.ghalla@catchacoach.com") || ( (values.email)[0] == "admin@catchacoach.com")){}
		else{
			alert("You Don't have Permission to Login");
			 return;
			throw new Error("You Don't have Permission to Login");
		}
		sessionStorage.removeItem('token');
		console.log((values.email)[0]);
		console.log((values.password)[0]);
		event.preventDefault();
		fetch('https://tst.catchacoach.systems/api/auth/local/',{
        	method: 'POST',
	        headers: {"Content-Type": "application/json",},
	        body: JSON.stringify({"identifier":(values.email)[0] , "password"  :(values.password)[0]}),
        })
        .then(response => {
        	if (!response.ok) {
		        return response.json().then(error => {
        			throw new Error(`Error: ${response.status} - ${error.message}`);
		        });
	        }
    	    return response.json();
        })
        .then(data => {
        	console.log("dddddddddddddddddddddd ", data.jwt);
		    sessionStorage.setItem('token', data.jwt);
		    navigate('/');
        })
        .catch(error => {
        	console.error('There was an error adding the post:', error);
        });		
//		setErrors(Validation(values));
//		if(errors.email === "" && errors.password === ""){
//			axios.post("https://dev.catchacoach.systems/api/auth/local/", values)
//			.then(res => {
//				if(res.data === "Success"){
//					navigate('/newpost');
//				}
//				else{
//					alert("login error");
//				}
//			})
//		}
	}
	return (
		<div className='d-flex justify-content-center align-items-center bg-light vh-100'>
			<div className='bg-white p-3 rounded w-25 border border-dark'>
				<h2> Sign-In </h2>
				<form action="" onSubmit={handleSubmit}>
					<div className='mb-3'>
						<label htmlFor="email"><strong>Email</strong></label>
						<input type="email" placeholder="Enter Email" name="email"
						onChange={handleInput} className="form-control rounded-0"/>
						{errors.email && <span className='text-danger'>{errors.email}</span>}
					</div>
					<div className='mb-3'>
						<label htmlFor="password"><strong>Password</strong></label>
						<input type="password" placholder="Enter Password" name="password"
						onChange={handleInput} className="form-control rounded-0"/>
						{errors.password && <span className='text-danger'>{errors.password}</span>}
					</div>
					<button type="submit" className="btn btn-Warning w100 rounded-0"> Log In </button>
				</form>
			</div>
		</div>

						

	)
}
export default LogIn ;
