import React, { Component } from 'react';
import Layout from './Layout'; // Import your Navbar component
import { MDBContainer } 	from 'mdb-react-ui-kit';
import CustomSelect 		from './components/multi_select'; // Adjust the path according to your file structure
import SingleSelect 		from './components/single_select';
import getUsers 			from './repos/dataService';
import createRedeem 		from './repos/create_redeem_code';

class RedeemCode extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dataList: [], // List of options 
			selectedOptions: [], // Selected options
			loading: true,
			selectedRedeem : null,
			createdCode:null,
			validDays: '',
			durationDays:'',
		};
	}
	static redeemOptions = [
		{value:"1", label: "Trainee can find a coach worldwide" },
		{value:"2", label: "Trainee can send unlimited number of sport partner requests" },
		{value:"3", label: "Coach has unlimited number of Clients" },
	];
//#######################################################################################################################################
	handleSelectRedeem = (selectedRedeem) => {
    	this.setState({ selectedRedeem });
		console.log('Selected:', selectedRedeem.value);
		this.fetchData(selectedRedeem.value)
		// Handle the selected option here (e.g., update state, make API call, etc.)
	};

//#######################################################################################################################################

	componentDidMount() {
//    	this.fetchData();
	}

//#######################################################################################################################################

	fetchData = async (code_value) => {
    	try {
			const data = await getUsers(code_value);
			const options = [
				{ value: 'select-all', label: 'Select All' },
				...data.map(item => ({ value: item.id, label: item.email }))
			];
			this.setState({ dataList: options, loading: false });
		} catch (error) {
			console.error('Error:', error);
			this.setState({ loading: false });
		}
	};
//#######################################################################################################################################
	handleChange = (selectedOptions) => {
    	const selectAllOption = selectedOptions.find(option => option.value === 'select-all');
	    if (selectAllOption) {
    		const allOptions = this.state.dataList.filter(option => option.value !== 'select-all');
			this.setState({
		        selectedOptions: selectAllOption ? allOptions : [],
			});
		} else {
			this.setState({ selectedOptions });
		}
	};
//#######################################################################################################################################
  // Extract selected emails and IDs
  getSelectedEmailsAndIds = () => {
	const message_id = this.state.selectedRedeem.value;
    const { selectedOptions } = this.state;
    return {message_id: message_id , users: selectedOptions.filter(option => option.value !== 'select-all').map(option => ({
      id: option.value,
      email: option.label,
    })),};
  };

//#######################################################################################################################################
  handleClick = async () => {
    const data = this.getSelectedEmailsAndIds();
	const createdCode = await createRedeem(data.message_id, data.users, this.state.validDays, this.state.durationDays);
	this.setState({createdCode});
	window.location.reload();
  };
//#######################################################################################################################################
	handleValidDaysChange = (event)		=> {
    	this.setState({ validDays:	event.target.value });
	};
	handleDurationDaysChange = (event)	=> {
    	this.setState({ durationDays:event.target.value });
	};
//#######################################################################################################################################

render() {
	const { dataList, selectedOptions, loading } = this.state;
	const { validDays } = this.state;
	const { durationDays } = this.state;

//    if (loading) {
//      return <div>Loading...</div>;
//    }

	return (
		<Layout>
			<MDBContainer>
				<div class="mb-4">
					<h2>Redeem Code Type</h2>
					<SingleSelect
						options={RedeemCode.redeemOptions}  // Pass the static options as a prop
						onChange={this.handleSelectRedeem}
						placeholder="Choose a Redeem code Type"
					/>
				</div>			

				<div  class="row">
					<div class="col-md-3">
						<h3>Enter Valid Days</h3>
					</div>
					<div class="col-md-3">
						<input type="text" class="form-control" 
							value={validDays}		onChange={this.handleValidDaysChange} placeholder="Enter Valid Days" />
					</div>
					<div class="col-md-3">
						<h3>Enter Duration Days</h3>
					</div>
					<div class="col-md-3">
						<input type="text" class="form-control" 
							value={durationDays}	onChange={this.handleDurationDaysChange} placeholder="Enter Duration Days" />
					</div>
				</div>
				<div class="row" /*style={{ minHeight:'200px', maxHeight: '400px', overflowY: 'auto', listStyleType: 'none', padding: 0 }}*/ >
					<div class="mb-6">
						<h2>Users </h2>
					</div>
					<div class="mb-6">
						<CustomSelect 
						options={dataList}
						selectedOptions={selectedOptions}
						handleChange={this.handleChange}
						placeholder="Select Users"
						/>
					</div>
				</div>			

				<button type="button" className="btn btn-primary" onClick={this.handleClick}>
					Assign Redeem Code
				</button>
			</MDBContainer>
		</Layout>
		);
	}
}

export default RedeemCode;
