import React, { useState } from 'react';
import axios from 'axios';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import Layout		from './pages/Layout'
import Homepage		from './pages/Homepage'
import Services		from './pages/Services'
import About		from './pages/About'
import Contact		from './pages/Contact'
import SiteHeader	from './components/SiteHeader'
import NewPost		from './pages/Create'     //./pages/NewPost
import LogIn		from './pages/LogIn'
import LogOut		from './pages/LogOut'
import Redeem		from './pages/redeem'

function App() {
  return (
    <div className="App">
	  <Router>
	      <Routes>
		  <Route path="/"			element={<Homepage 		/>} />
		  <Route path="/services"	element={<Services 		/>} />
		  <Route path="/about"		element={<About    		/>} />
		  <Route path="/contact"	element={<Contact  		/>} />
		  <Route path="/newpost"	element={<NewPost  		/>} />
		  <Route path="/login"		element={<LogIn    		/>} />
		  <Route path="/logout"		element={<LogOut   		/>} />
		  <Route path="/redeem"		element={<Redeem		/>} />

	      </Routes>
	  </Router>
    </div>
  );
}

export default App;
