const fetchDataFromServer = async (url, options = {}) => {
	try {
		const response = await fetch(url, options);
		if (!response.ok) {
			throw new Error(`Error: ${response.status} ${response.statusText}`);
		}
    	if(response.status == 200){
        	alert("Code added Successfully");
	    }
			
		const data = await response.json();
		return data;
	} catch (error) {
		console.error('Failed to fetch data:', error);
		throw error;
	}
};

const createCodetoUsers = async(message_id, users_ids, validDays, durationDays) => {
   	const url = 'https://tst.catchacoach.systems/api/redeem-codes/createCodetoUsers'; // Replace with your API endpoint
	const token = sessionStorage.getItem('token'); // Assuming you're using a token for authentication
	const options = {
       	method: 'POST',
        headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${token}`,
		},
			body: JSON.stringify({"data":{message_id:message_id, users_ids: users_ids, validDays:validDays, durationDays:durationDays}}),
	};
	const response = await fetchDataFromServer(url, options);
	return response ;
};


export default createCodetoUsers
