import React , { useEffect } from 'react'
import Layout from './Layout'; // Import your Navbar component
import Carousel from 'react-bootstrap/Carousel';

const About = () => {
    return (
	<Layout  >
	    <h1>About</h1>
	</Layout>
  	);
};


export default About;
