import React , { useEffect } from 'react'
import Navbar from './Navbar'; // Import your Navbar component
import Footer from './Footer' ;

const Layout = ({ children }) => {
//    useEffect(() => {
	    // Code that runs when the component mounts (page loads)
	    // console.log('Component mounted!');
	    // const token = sessionStorage.getItem('token');
//	    if(token){
//		    console.log("token : ", token);
//	    }
//	    else{
//		    console.log("loged out");
//	    }
	    // Optional: Cleanup function to prevent memory leaks
//	    return () => {
//		    console.log('Component unmounted!');
//	    };
//    }, []); // Empty dependency array ensures it runs only once


	return (
		<body class="bg-white">
		<div className="layout">
			<Navbar />
			<main>{children}</main>
			<Footer />
		</div>
		</body>
  	);
}


export default Layout;
