import React from 'react';
import Select from 'react-select';

const CustomSingleValue = (props) => {
  const { data, selectOption, clearValue, getValue, options } = props;
  const isSelectAll = data.value === 'select-all';

  return isSelectAll ? (
    <div
      onClick={() => {
        if (getValue().length === options.filter(option => option.value !== 'select-all').length) {
          clearValue();
        } else {
          selectOption(options.filter(option => option.value !== 'select-all'));
        }
      }}
      style={{ cursor: 'pointer', color: '#007bff' }}
    >
      {getValue().length === options.filter(option => option.value !== 'select-all').length ? 'Deselect All' : 'Select All'}
    </div>
  ) : (
    <components.SingleValue {...props} />
  );
};

const CustomSelect = ({ options, selectedOptions, handleChange, placeholder }) => (
  <Select
    isMulti
    options={options}
    value={selectedOptions}
    onChange={handleChange}
    components={{ SingleValue: CustomSingleValue }}
    placeholder={placeholder}
    styles={{
      menu: (provided) => ({
        ...provided,
        maxHeight: 200, // Set a maximum height for the dropdown menu
        overflowY: 'auto', // Enable vertical scrolling
      }),
      menuList: (provided) => ({
        ...provided,
        padding: 0, // Remove padding if needed
      }),
    }}
  />
);

export default CustomSelect;
