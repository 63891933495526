import React , { Component , useState } from 'react'
import Layout from './Layout'; // Import your Navbar component
import Carousel from 'react-bootstrap/Carousel';

import { MDBSelect, MDBSelectInput, MDBSelectOptions, MDBSelectOption, MDBContainer } from 'mdb-react-ui-kit';

class Services extends Component {
    render(){

    return (
	<Layout  >
	<div class="container">
		<div class="row">
		    <div>
			</div>
		</div>

	    <div class="row">  
 	        <div class="col-md-4"> 
	            <h2>Content 3</h2>
		    <p>This is some content for the first section of your page.</p>
		    <img src="https://catchacoach.de/wp-content/uploads/2020/10/yoga.jpg" class="img-thumbnail rounded" alt="..."></img>

	        </div>
	        <div class="col-md-4">
	            <h2>Content 4</h2>
		    <p>This is some content for the first section of your page.</p>
		    <img src="https://catchacoach.de/wp-content/uploads/2020/10/nutritions.jpg" class="img-thumbnail rounded " alt="..."></img>
	        </div>
	        <div class="col-md-4">
	            <h2>Content 5</h2>
		    <p>This is some content for the first section of your page.</p>
		    <img src="https://catchacoach.de/wp-content/uploads/2024/05/f10cfd0d-e330-4d85-ae8d-4e361f9fa04a.png" class="img-thumbnail rounded mx-auto d-block" alt="..."></img>
	        </div>
	    </div>
	    <div class="row">  
 	        <div class="col-md-4"> 
	            <h2>Content 6</h2>
		    <p>This is some content for the first section of your page.</p>
		    <img src="https://catchacoach.de/wp-content/uploads/2024/05/Untitled.png" class="img-thumbnail" alt="..."></img>
	        </div>
	        <div class="col-md-4">
	            <h2>Content 7</h2>
		    <p>This is some content for the first section of your page.</p>
		    <img src="https://catchacoach.de/wp-content/uploads/2024/05/man-runner-running-8293794-1.jpg" class="img-thumbnail" alt="..."></img>
		        </div>
	    		    <div class="col-md-4">
			            <h2>Content 8</h2>
		    			<p>This is some content for the first section of your page.</p>
					    <img src="..." class="img-thumbnail" alt="..."></img>
	    		    </div>
			    </div>
			</div>		
		</Layout>
  		);
}}


export default Services;
