import React , { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
function Navbar() { 
	const [ auth, setAuth] = useState(false)

	useEffect(() => {
		const token = sessionStorage.getItem('token');
		if(token){setAuth(true);}
		else{setAuth(false);}
	}, [])
  return (

<nav class="navbar navbar-expand-lg bg-body-white ">
  <div class="container-fluid">
      <a class="navbar-brand" href="/">
        <img src="https://catchacoach.de/wp-content/uploads/2024/05/cropped-logo_removebg-1-164x104.png" alt="Your Brand Logo"  class="d-inline-block align-top"></img>
      </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
        <li class="nav-item mx-2 ">
          <a class="nav-link active bg-warning rounded-pill" aria-current="page" href="/">Home</a>
        </li>
        <li class="nav-item mx-2">
          <a class="nav-link active bg-warning rounded-pill" aria-current="page"  href="/services">Services</a>
        </li>
        <li class="nav-item mx-2">
          <a class="nav-link active bg-warning rounded-pill" aria-current="page"  href="/redeem">Redeem Code</a>
        </li>
        <li class="nav-item mx-2">
          <a class="nav-link active bg-warning rounded-pill" aria-current="page" href="/about">About</a>
        </li>
        <li class="nav-item mx-2 ">
          <a class="nav-link active bg-warning rounded-pill" aria-current="page" href="/contact">Contact</a>
        </li>
        <li class="nav-item mx-2">
          <a class="nav-link active bg-warning rounded-pill" id="login" href= {auth ? "/logout" : "/login" } >
	  	{auth ? 'logout' : 'login'} 
	  </a>
        </li>
      </ul>
    </div>

    <div>
      <ul class="d-flex navbar-nav ms-auto mb-5 mb-lg-0">
      </ul>
    </div>
  </div>
</nav>
  );
}

export default Navbar;

