import React , { useEffect } from 'react'
import Layout from './Layout'; // Import your Navbar component

const Contact = () => {
    return (
	<Layout  >
	    <h1>Contact Us</h1>
	</Layout>
  	);
};


export default Contact;
