import { useState } from "react" ;

import axios from 'axios';

const Create = () => {

	const [ postTitle, setTitle] = useState('');
	const [ postIntro, setIntro] = useState('');
	const [ postText , setBody ] = useState('');

	const [selectedFile, setSelectedFile] = useState(null);
	
  	const handleFileChange = (event) => {
    		setSelectedFile(event.target.files[0]);
  	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const post = { postTitle, postIntro, postText };
		const token = sessionStorage.getItem('token');

		const formResponse = await fetch('https://dev.catchacoach.systems/api/new-feeds',{
			method: 'POST',
			headers: {
				"Content-Type": "application/json",
			  	'Authorization': 'Bearer '+token ,
  			},
			body: JSON.stringify({"data":post}),
		});
		if(!formResponse.ok){
			throw new Error("Form Submission faild");
		}
		const formResult = await formResponse.json();
		const postId = formResult.data.id;
		console.log(postId);
	 	console.log(selectedFile);

		const fileData = new FormData();
	    	fileData.append('files', selectedFile);
		fileData.append('refId', postId);
		fileData.append('ref',"api::new-feed.new-feed");
		fileData.append('field',"photo");

		console.log(token);

                const upload_res = await axios({
                        method: "POST",
                        url : "https://dev.catchacoach.systems/api/upload",
                        data: fileData,
                      headers: {
                              "Content-Type": "multipart/form-data",
                              'Authorization': 'Bearer '+ token ,
                      },
			
                });
		console.log("the result is : ", upload_res);		

//		const fileResponse = await fetch('https://dev.catchacoach.systems/api/upload', {
//	        	method: 'POST',
//			headers: {
//				"Content-Type": "application/json",
//		  		'Authorization': 'Bearer '+ token ,
//			},
//			body:JSON.stringify({'files': selectedFile}) ,
//		});
//		if (!fileResponse.ok) {
//			throw new Error('File upload failed');
//		}		
//		const fileResult = await fileResponse.json();
//		console.log('File uploaded successfully:', fileResult);
		setTitle('');
		setIntro('');
		setBody('');
	}
	return (
	  <div className='d-flex justify-content-center align-items-center bg-light vh-100'>
              <div className='bg-white p-3 rounded w-50 border border-dark'>

		  <h2> Add a New Post </h2>
		  <form onSubmit={handleSubmit}>
		    <div className='mb-3'>
		      <label><strong> Post Title </strong></label>
		      <input type="text" required
		    	value={postTitle}
		    	onChange={(e) => setTitle(e.target.value)}
			 className="form-control rounded-0"
		      />
		    </div>
		    <div className='mb-3'>
		      <label> Post Intro: </label>
		      <textarea required 
		    	value={postIntro}
		    	onChange={(e) => setIntro(e.target.value)}
			 className="form-control rounded-0"
		      ></textarea>
		    </div>
		 <div className='mb-4'>
		    <label> Post Body: </label>
		    <textarea required 
		    	value={postText}  style={{height: '500px'}}
		    	onChange={(e) => setBody(e.target.value)}
			 className="form-control rounded-0"
		    ></textarea>
		</div>
		<div className="mb-3">
		  <input className="form-control" type="file" id="formFile" onChange  = {handleFileChange}/>
		</div>

		<button type="submit" className="btn btn-success w100 rounded-0"> Add Post </button>
	      </form>
	    </div>
	</div>
	);
}

export default Create;
