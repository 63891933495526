import React, { useState } from 'react';
import Select from 'react-select';

const SingleSelect = ({ options, onChange, placeholder }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleChange = (option) => {
    setSelectedOption(option);
    onChange(option); // Call the passed onChange function with the selected option
  };

  return (
    <Select
      value={selectedOption}
      onChange={handleChange}
      options={options}
      placeholder={placeholder || "Select an option"}
    />
  );
};

export default SingleSelect;
